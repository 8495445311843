<nav-breadcrumb [nameParent]="nameParent"> dfghdcf</nav-breadcrumb>
<app-loader *ngIf="loader"></app-loader>
<mat-card>
  <br>
  <div style="text-align: center; color: grey">
    <ul style="display: inline-block; text-align: left;">
      <li>Enter a password min 8 characters (<span style="font-weight: bold;">must contain- 1 Uppercase, 1 Lowercase, 1
          numeric, 1 symbol</span>).</li>
      <li>Your new password must be different from previously used passwords.</li>
    </ul>
  </div>
  <form [formGroup]="PasswordChangeForm">
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="15"
        fxFlex.gt-xs="15"
        fxFlex.gt-md="15"
        fxFlex.gt-lg="15">

      </div>
      <div fxFlex.gt-sm="70"
        fxFlex.gt-xs="70"
        fxFlex.gt-md="70"
        fxFlex.gt-lg="70">

        <div>
          <mat-form-field appearance="outline">
            <mat-label style="font-size: 15px;">Old Password</mat-label>
            <input matInput
              [type]="hide1 ? 'password' : 'text'"
              style="padding: 0 !important;"
              [formControlName]="'oldPassword'"
              name="oldPassword"
              required>
            <button mat-icon-button
              matSuffix
              (click)="hide1 = !hide1"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide1"
              type="button">
              <mat-icon style="color:#929292">{{hide1 ? "visibility" : "visibility_off"}}</mat-icon>
            </button>
            <mat-error class="errMsg"
              *ngIf="!PasswordChangeForm.get('oldPassword').valid && PasswordChangeForm.get('oldPassword').touched">
              Please provide old password
            </mat-error>
          </mat-form-field>
        </div>






        <mat-form-field appearance="outline">
          <mat-label style="font-size: 15px;">New Password</mat-label>
          <input matInput
            [type]="hide2 ? 'password' : 'text'"
            type="password"
            style="padding: 0 !important;"
            [formControlName]="'newPassword'"
            name="newPassword"
            (blur)="passwordLostFocus($event)"
            required>
          <button mat-icon-button
            matSuffix
            (click)="hide2 = !hide2"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide2"
            type="button">
            <mat-icon style="color:#929292">{{hide2 ? "visibility" : "visibility_off"}}</mat-icon>
          </button>
          <mat-error class="errMsg"
            *ngIf="!PasswordChangeForm.get('newPassword').valid && PasswordChangeForm.get('newPassword').touched">
            Please provide new password
          </mat-error>
        </mat-form-field>





        <mat-form-field appearance="outline">
          <mat-label style="font-size: 15px;">Confirm password</mat-label>
          <input matInput
            [type]="hide3 ? 'password' : 'text'"
            type="password"
            style="padding: 0 !important;"
            [formControlName]="'confirmPassword'"
            name="confirmPassword"
            (blur)="confirmPasswordLostFocus($event)"
            required>
          <button mat-icon-button
            matSuffix
            (click)="hide3 = !hide3"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide3"
            type="button">
            <mat-icon style="color:#929292">{{hide3 ? "visibility" : "visibility_off"}}</mat-icon>
          </button>
          <mat-error class="errMsg"
            *ngIf="!PasswordChangeForm.get('confirmPassword').valid && PasswordChangeForm.get('confirmPassword').touched">
            Please provide confirm password
          </mat-error>
        </mat-form-field>

      </div>

      <div fxFlex.gt-sm="15"
        fxFlex.gt-xs="15"
        fxFlex.gt-md="15"
        fxFlex.gt-lg="15">

      </div>
    </div>
    <br>
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="15"
        fxFlex.gt-xs="15"
        fxFlex.gt-md="15"
        fxFlex.gt-lg="15">

      </div>
      <div fxFlex.gt-sm="70"
        fxFlex.gt-xs="70"
        fxFlex.gt-md="70"
        fxFlex.gt-lg="70"
        style="display: flex;">
        <!-- submitButton pure-button pure-button-primary -->
        <button class="save-button"
          style="display: flex; text-align: center; margin-right: 10px;"
          (click)="changePassword()"
          [disabled]="loader"
          [hidden]="loader">
          <mat-icon>save</mat-icon>
          <span>Save Changes</span>&nbsp;&nbsp;
        </button>
        <!-- <app-custom-loader> </app-custom-loader> -->

        <button mat-raised-button
          class="cancel-button"
          (click)="resetFields()">
          <mat-icon>settings_backup_restore</mat-icon>
          <span>Reset</span>&nbsp;&nbsp;

        </button>
      </div>
      <div fxFlex.gt-sm="15"
        fxFlex.gt-xs="15"
        fxFlex.gt-md="15"
        fxFlex.gt-lg="15">

      </div>
    </div>
  </form>
  <br>
  <br>
  <br>
</mat-card>
