import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { GlobalService, LocalStorageService } from "../services/global.service";
import { SnackbarService } from "../services/snackbar.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  public passhide: boolean = true;
  public cpasshide: boolean = true;
  institutionData: any;
  verificationInfo: any;
  user_username: any;

  public resetPassword: any = FormGroup;

  public isLoading: boolean = false;
  public loader: boolean = false;
  constructor (
    private LocalStorage: LocalStorageService,
    private route: Router,
    private auth: AuthService,
    private global: GlobalService,
    private snackBar: SnackbarService
  ) {
    this.resetPassword = new FormGroup({
      user_username: new FormControl(),
      password: new FormControl(),
      cPassword: new FormControl(),
    });

    this.institutionData = this.LocalStorage.getItem("institutionData") || null;
    this.user_username = this.LocalStorage.getItem("user_username") || null;
    this.resetPassword.value.user_username = this.user_username;
    this.verificationInfo =
      this.LocalStorage.getItem("verificationData") || null;
  }

  ngOnInit(): void {
    if (!this.institutionData) {
      this.onBackToLogin();
    }

    if (
      !this.user_username ||
      !this.verificationInfo ||
      !this.verificationInfo.phone ||
      !this.verificationInfo.email
    ) {
      // this.onBackToLogin();
    }
  }
  async onSubmit() {
    if (!this.user_username) {
      this.onBackToLogin();
    }
    /* if (!this.formValidation()) {
      return;
    } */

    if (
      (await this.passwordValidation()) !== true &&
      (await this.cPasswordValidation()) !== true
    ) {
      return;
    }

    this.resetPassword.value.user_username = this.user_username;

    if (
      JSON.stringify(this.resetPassword.value.password) !==
      JSON.stringify(this.resetPassword.value.cPassword)
    ) {

      this.snackBar.warning("Password and Confirm Password does not match");
      return;
    }

    const regexABC = /[A-Z]/g;
    const regex123 = /[0-9]/g;
    const regex = /[@$!%*?&]/g;
    const foundExp = this.resetPassword.value.password.match(regex);
    const found123 = this.resetPassword.value.password.match(regex123);
    const foundABC = this.resetPassword.value.password.match(regexABC);

    if (!found123 || !foundExp || !foundABC) {
      this.snackBar.error(
        "Password is not valid. Please follow the password rules."
      );
      return;
    }
    this.isLoading = true;
    this.loader = true;
    await this.auth
      .changePassword(this.resetPassword.value)
      .subscribe((res: any) => {
        if (!res.status && res?.validation_error) {
          const validation = res?.validation_error;

          if (validation.user_username) {
            this.snackBar.error(
              validation.user_username[0] || "Something went wrong"
            );
          }

          if (validation.password) {
            this.snackBar.error(
              validation.password[0] || "Something went wrong"
            );
          }
          this.isLoading = false;
          this.loader = false;
          return;
        }

        this.isLoading = false;
        this.loader = false;

        if (!res.status) {
          this.snackBar.error(res.message || "Something went wrong");
          return;
        }

        if (res.status) {
          this.onBackToLogin();
          this.snackBar.success(res.message || "Your Password updated");
        }
      });
  }
  formValidation() {
    return true;
  }

  passwordValidation() {
    if (!this.resetPassword.value.password) {
      return "Password is required";
    }
    return true;
  }
  cPasswordValidation() {
    if (!this.resetPassword.value.cPassword) {
      return "Confirm Password is required";
    }
    return true;
  }

  applyPasswordFilter(val: string) {
  }
  onBackToLogin() {
    this.LocalStorage.clearAll();
    this.route.navigate(["/"]);
  }
}
