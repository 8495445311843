<mat-card color="Accent"
  class="screen-title  mat-elevation-z8">
  <mat-expansion-panel hideToggle
    disabled>
    <mat-expansion-panel-header style="background-color:#283593 !important">
      <mat-panel-title style="color:whitesmoke; padding: 8px; font-size: 13px; display: flex;align-items: center;">
        <!-- You are here&nbsp; -->
        <mat-icon>{{icon}}</mat-icon>&nbsp;{{nameParent}}
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-card>