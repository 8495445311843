import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, throwError  } from "rxjs";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/Rx";
import { HttpV2Service } from './http-v2.service';
import { HttpService } from "./http.service";
import { OldAPIHttpService } from "./old-api-http.service";
const helper = new JwtHelperService();
@Injectable({
  providedIn: "root",
})
export class GlobalService {
globalData: any;
public user_name:any;
  panelClass: string = "";
  constructor (
    private _http: HttpService,
    private http: OldAPIHttpService,
    private httpV2: HttpV2Service,
    private httpClient: HttpClient
  ) {


  }
  public UserInfo(){
    let userInfo=  {
        "is_admin": 0,
        "first_name": "Head Of",
        "last_name": "HR",
        "gender": true,
        "user_id": 2001150003,
        "user_type": 2,
        "full_name": "Head Of HR",
        "mobile": "01914010900",
        "gender_name": "Male"
      }
      return userInfo;
  }

  public  getAllDataFormNewApiV2(params: any): Observable<any> {

    return  this.httpV2.post("system-configuration/read-all-global-data", params).map(
         res => {
        const data = res;
         this.user_name=res.data.user_basic_info.first_name;
          console.log('Console Log From Service -- ', data);
        //return JSON.stringify(data);
        return data;
      })
  }


  public getMenuItems(): Observable<any> {
    return this._http.get("read-all-menus/1").map((res) => res);
  }

  public getAllDataFormNewApi(): Observable<any> {
    return this._http.get("all-data").map((res) => res);
  }


  public getInstitution(): Observable<any> {
    return this._http.get("institution-data").map((res) => res);
  }

  public getInstitutionAuth(): Observable<any> {
    return this._http.get("auth/institution-data").map((res) => res);
  }

  public getClass(): Observable<any> {
    return this._http.get("global/classes").map((res) => res);
  }

  public getSessionCurrentUpcoming(id: number): Observable<any> {
    return this._http
      .get("/global/session/current-upcoming/" + id)
      .map((res) => res);
  }

  public getAllData = (userId: number, sessionId?: number): Observable<any> => {
    return this.http
      .get(
        "global_info/all_data?user_id=" + userId + (sessionId
          ? "&session_id=" + sessionId
          : "")
      )
      .map((res) => res);
  };



  // node version v2
  public getInstitutionV2(): Observable<any> {
    return this.httpV2.get("system-configuration/institution-data").map((res) => res);
  }

  public getInstitutionAuthV2(): Observable<any> {
    return this.httpV2.get("system-configuration/auth/institution-data").map((res) => res);
  }

  public getMenuItemsV2(): Observable<any> {
    return this.httpV2.get("system-configuration/read-all-menu/1").map((res) => res);
  }

  // public  getAllDataFormNewApiV2(params: any): Observable<any> {

  //   return  this.httpV2.post("system-configuration/read-all-global-data", params).map(
  //        res => {
  //       const data = res;
  //       // this.user_Name=res.data.user_basic_info.first_name;
  //         console.log('Console Log From Service -- ',data);
  //       //return JSON.stringify(data);
  //       return data;
  //     })
  // }


  // getAllDataFormNewApiV2(params: any): Observable<any> {

  //   //const URL='http://127.0.0.1:3000/getnonadminuser/'+ username;
  //  params={"userId" : 2001150003, "sessionId" : 33};
  //   const URL = 'http://172.30.100.5:2226/api/v2/system-configuration/read-all-global-data';
  //   return this.http.post(URL,params).map(
  //     res => {
  //       const data = res;
  //         console.log('Console Log From Service -- ',data);
  //       //return JSON.stringify(data);
  //       return data;
  //     })
  // }


  public getUserInstitutionData(): Observable<any> {
    return this.httpV2.get("system-configuration/get-user-data").map((res) => res);
  }


  public getApi(): Observable<any> {
    return this.httpClient.get("https://jsonip.com/").map((res) => res);
  }
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  public setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  public getItem<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data);
    }
    return null;
  }
  public clearAll(): void {
    return localStorage.clear();
  }
}

@Injectable({
  providedIn: "root",
})
export class paginatorVariable {
  pageSizeOptions: number[] = [20, 50, 100];
  take: number = 10;
  skip: number = 0;
  page: number = 1;
  pageSize: number = 10;
  length: number = 10;
}
