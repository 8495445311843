import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nav-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadCrumbComponent implements OnInit {

  @Input() icon: string = '';
  @Input() nameParent: string = '';

  constructor () { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // console.log(this.icon);
    // console.log(this.nameParent);
  }

}
