import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import {
  GlobalService,
  LocalStorageService,
} from "./../services/global.service";
const helper = new JwtHelperService();

@Injectable()
export class GuardGuard implements CanActivate {
  public loginFlag: any;
  public who: any;
  accessToken: string | null = null;
  userId: any | null = null;
  globalData: any;
  constructor (
    private LocalStorage: LocalStorageService,
    private Router: Router,
    private global: GlobalService
  ) {
    this.globalData = this.LocalStorage.getItem("allData");
    this.userId = this.LocalStorage.getItem("user") || null;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.accessToken = this.LocalStorage.getItem("access_token");
    this.userId = this.LocalStorage.getItem("user");

    // TODO:: currently not working
    // const decodedToken = helper.decodeToken(JSON.stringify(this.accessToken));
    // const expirationDate = helper.getTokenExpirationDate(
    //   JSON.stringify(this.accessToken)
    // );

    const isExpired = helper.isTokenExpired(this.accessToken || "");
    if (this.userId && !isExpired) {

      if (!this.globalData && this.userId) {
        //* All Data *//
        // this.global.getAllDataFormNewApi().subscribe((res) => {
        //   this.LocalStorage.setItem("allData", res.data);
        //   console.log("🚀 ~ file: guard.guard.ts ~ line 57 ~ GuardGuard ~ this.global.getAllDataFormNewApi ~ res.data", res.data)
        // });
        console.log("for all data V2");

        /* this.global.getAllDataFormNewApiV2({
          userId: + this.userId,
          sessionId: 30,
          studentId: null
        }).subscribe((res) => {
          console.log("🚀 ~ file: guard.guard.ts ~ line 67 ~ GuardGuard ~ res.data", res.data)
          this.LocalStorage.setItem("allData", res.data);
        }); */
      }

      return true;
    }

    this.LocalStorage.clearAll();
    this.Router.navigate(["/"]);
    return false;
  }
}
