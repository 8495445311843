import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../material-component/snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  public error(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: "close",
        type: "Error",
      },
      duration: 2500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ["my-error-snackbar"],
    });
  }
  public success(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: "check",
        type: "Success",
      },
      duration: 2500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ["my-success-snackbar"],
    });
  }
  public warning(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: "warning",
        type: "Warning",
      },
      duration: 2500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ["my-warning-snackbar"],
    });
  }
  public info(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: "info",
        type: "Info",
      },
      duration: 2500,
      verticalPosition: "top",
      horizontalPosition: "end",
      panelClass: ["my-info-snackbar"],
    });
  }
}
