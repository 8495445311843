import { CustomLoaderModule } from './custom-loader/custom-loader.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from 'src/app/common-material/loader/loader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from './loader/loader.module';
import { CustomLoaderComponent } from './custom-loader/custom-loader.component';



@NgModule({
  imports: [
    CommonModule,
    LoaderModule,
    MatProgressSpinnerModule,
    CustomLoaderModule
  ]
})
export class CommonMaterialModule { }
