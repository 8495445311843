import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { LocalStorageService } from "./global.service";
export interface HttpService {
  BASE_URL: string;
  headers?: any;
  accessToken: string | null;
  get: (path: string) => Observable<any>;
  post: (path: string, formData: any) => Observable<any>;
  put: (path: string, formData: any) => Observable<any>;
  delete: (path: string) => Observable<any>;
}

@Injectable({
  providedIn: "root",
})
export class HttpV2Service implements HttpService {
  BASE_URL: string = "";
  accessToken: string | null = null;
  constructor (
    private _http: HttpClient,
    private LocalStorage: LocalStorageService
  ) {
    this.BASE_URL = environment.apiUrlV2;
    this.accessToken = LocalStorage.getItem("access_token");
  }
  header = () => {
    return {
      headers: {
        Authorization: "Bearer " + this.LocalStorage.getItem("access_token"),
      },
    };
  };

  get = (path: string): Observable<any> => {
    return this._http
      .get(this.BASE_URL + path, this.header())
      .map((res) => res)
      .catch((err: any) => {
        return Observable.throwError(err);
      });
  };

  post = (path: string, formData: any): Observable<any> => {
    return this._http
      .post(this.BASE_URL + path, formData, this.header())
      .map((res) => res)
      .catch((err: any) => {
        return Observable.throwError(err);
      });
  };

  put = (path: string, formData: any): Observable<any> => {
    return this._http
      .put(this.BASE_URL + path, formData, this.header())
      .map((res) => res)
      .catch((err: any) => Observable.throwError(err));
  };

  delete = (path: string): Observable<any> => {
    return this._http
      .delete(this.BASE_URL + path, this.header())
      .map((res) => res)
      .catch((err: any) => Observable.throwError(err));
  };

  print = (path: string): Observable<any> => {
    // window.open(this._http.get(this.BASE_URL + path, this.header()), "_blank");

    return this._http
      .delete(this.BASE_URL + path, this.header())
      .map((res) => res)
      .catch((err: any) => Observable.throwError(err));
  };
}
