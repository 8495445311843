import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { LocalStorageService } from "../services/global.service";
import { SnackbarService } from "../services/snackbar.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  institutionData: any;
  /* forgotForm: any = {
    user_username: "",
    phone: "",
  }; */

  public forgotForm: any = FormGroup;
  errorMsg: any = {
    user_username: "",
    phone: "",
    usernameTrigger: false,
    phoneTrigger: false,
  };
  hide: boolean = false;
  isLoading: boolean = false;
  loader: boolean = false;
  constructor (
    private LocalStorage: LocalStorageService,
    private router: Router,
    private auth: AuthService,
    private snackBar: SnackbarService
  ) {
    this.institutionData = this.LocalStorage.getItem("institutionData") || null;
  }

  ngOnInit(): void {
    this.forgotForm = new FormGroup({
      user_username: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
      ]),
      phone: new FormControl(),
    });

    if (!this.institutionData) {
      this.LocalStorage.clearAll();
      this.router.navigate(["/"]);
    }
  }

  async forgotPassword() {
    /* if (await !this.formValidation()) {
      return;
    } */

    if (
      (await this.userUsernameValidation()) !== true &&
      (await this.phoneValidation()) !== true
    )
      return false;
    this.isLoading = true;
    this.loader = true;
    await this.auth
      .forgotPassword(this.forgotForm.value)
      .subscribe((res: any) => {
        this.isLoading = false;
        this.loader = false;
        /* if (!res.status && res?.validation_error) {
          const validation = res?.validation_error;

          if (validation.user_username) {
            this.errorMsg.user_username = validation.user_username;
            this.errorMsg.usernameTrigger = true;
          }

          if (validation.phone) {
            // console.log(validation.phone);
          }
          return;
        } */

        if (!res.status) {
          this.loader = false;
          this.snackBar.error(res.message);
          return;
        }

        if (res.isOtpVerification) {
          const data = res?.data;
          this.snackBar.success(res.message);
          this.LocalStorage.setItem("user_username", data.user_username);
          this.LocalStorage.setItem("verificationData", data);
          this.router.navigate(["/otp-verification"]);
        }
      });
  }

  formValidation() {
    if (!this.forgotForm.user_username) {
      this.errorMsg.user_username = "Please enter username";
      this.loader = false;
      this.snackBar.error(this.errorMsg.user_username);

      return false;
    }
    if (!this.forgotForm.phone) {
      this.snackBar.error(this.errorMsg.phone || "Login Successful");
      return false;
    }
    return true;
  }
  userUsernameValidation() {
    if (!this.forgotForm.value.user_username) {
      // this.errorMsg.user_username = "";
      return "User username field is required";
    }
    /* else {
      return this.errorMsg.user_username;
    } */
    return true;
  }

  phoneValidation() {
    if (!this.forgotForm.value.phone) {
      return "Phone field is required";
    }
    return true;
  }
  onUserUsernameBlur() {
    if (
      this.forgotForm.value.user_username &&
      this.forgotForm.value.user_username.length > 10
    ) {
      this.snackBar.error("The user username must be 10 digits.");
    }
    if (isNaN(this.forgotForm.value.user_username)) {
      this.snackBar.error("The user username must be a number");
    }
  }

  onPasswordBlur() {
    if (
      this.forgotForm.value.phone &&
      this.forgotForm.value.phone.length < 11
    ) {
      this.snackBar.error("The phone must be at least 11 characters.");
    }
    if (isNaN(this.forgotForm.value.phone)) {
      this.snackBar.error("The phone format is invalid");
    }
  }
}
