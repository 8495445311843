<app-loader  *ngIf="loader"></app-loader>
<div>
  <!-- <mat-form-field appearance="standard" class="searchField" style="margin-right: 10px;">
            <mat-label style="font-size: 14px;">Search</mat-label>
            <input matInput style="font-size: 14px; height: 20px !important;">
            <mat-icon matSuffix style="font-size: 14px;">search</mat-icon>
          </mat-form-field> -->

  <!-- flag  -->
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="headerIcon">flag</mat-icon>
  </button>
  <mat-menu #menu="matMenu" style="width: 100px;">
    <button mat-menu-item style="display: flex; align-items: center;  ">
      <!-- <mat-icon>dialpad</mat-icon> -->
      <img src="/assets/bangladesh.svg" alt="Flag Image" style="width: 20px; height: 20px; " />
      <span>Bangla</span>
    </button>
    <button mat-menu-item style="display: flex; align-items: center; padding-top : 10px;">
      <img src="/assets/usa.svg" alt="Flag Image" style="width: 20px; height: 20px;" />
      <span>English</span>
    </button>
  </mat-menu>

  <!-- full screen  -->
  <button mat-icon-button (click)="openFullscreen()" *ngIf="fullScreen">
    <mat-icon class="headerIcon">fullscreen</mat-icon>
  </button>
  <button mat-icon-button (click)="closeFullscreen()" *ngIf="exitFullScreen">
    <mat-icon class="headerIcon">fullscreen_exit</mat-icon>
  </button>

  <!-- notification -->
  <button [matMenuTriggerFor]="notification" mat-icon-button class="m-r-5">
    <!-- <img src="assets/notification.png"  alt="user" class="profile-pic">  -->
    <mat-icon matBadge="15" matBadgeColor="warn" class="headerIcon">notification_important</mat-icon>
  </button>
  <mat-menu #notification="matMenu" class="mymegamenu">
    <mat-list role="list">
      <mat-list-item>Item 1</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>Item 2</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>Item 3</mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>Item 4</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </mat-menu>

  <!-- profile setting -->
  <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5" >
    <img src="assets/avatar.png" alt="user" class="profile-pic" />
  </button>

  <mat-menu #profile="matMenu" class="mymegamenu "  >
    <div fxlayout="row" fxlayoutalign="center" style="display: flex;align-items: center; padding: 15px; background-color: #283593 !important; color:white ;" >
      <img alt="user-image" class="user-image" src="assets/avatar.png">
      <div fxlayout="column" fxlayoutalign="center center"
        style="flex-direction: column; box-sizing: border-box; place-content: center; align-items: center;">


        <!-- <small *ngIf="userInfo">{{userInfo.first_name+" "+userInfo.last_name}}<br>
          {{userInfo.designation}}<br>

        </small> -->

         <small>{{name}}<br>
          <br>

        </small>
      </div>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item type="button" routerLink="/profile">
      <mat-icon>account_box</mat-icon> Profile
    </button>
    <button mat-menu-item>
      <mat-icon>settings</mat-icon> Settings
    </button>

    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon> Disable notifications
    </button>
    <button mat-menu-item>
      <mat-icon>help</mat-icon> Help
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="LOGOUT()">
      <mat-icon>power_settings_new</mat-icon> Sign Out
    </button>
  </mat-menu>
</div>

