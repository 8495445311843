import { MediaMatcher } from "@angular/cdk/layout";
import { AppHeaderComponent } from "./header/header.component";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/services/global.service";
import { GlobalDataService } from "src/app/services/utils/global-data.service";
import { MenuItems } from "../../shared/menu-items/menu-items";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: [],
})
export class FullComponent implements OnDestroy, AfterViewInit {
  isActive = true;
  mobileQuery: MediaQueryList;
  authenticated: boolean = true;
  public logoStyle: any;
  public textLineHeight: any;
  public institutionData: any;

  private _mobileQueryListener: () => void;

  constructor (
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router,
    private LocalStorage: LocalStorageService,
    private globalData: GlobalDataService,
    private appheader:AppHeaderComponent
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.appheader.ngOnInit()
    if (this.LocalStorage.getItem("institutionData")) {
      this.institutionData = this.LocalStorage.getItem("institutionData");
      if (this.institutionData.slug.toLowerCase() == "ois") {

        this.logoStyle = `
        height: 50px;
        width: auto;
        `;
      } else {
        this.logoStyle = `
        height: 30px;
        width: auto
        `;
      }
    } else {
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() { }

  onDashboard() {
    // console.log("fisc");

    this.router.navigate(["/dashboard"]);
  }
}
