import { ChangePasswordComponent } from './self-care/profile/change-password/change-password.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GuardGuard } from "./auth/guard.guard";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";

import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./login/login.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { OtpVerifyComponent } from "./otp-verify/otp-verify.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const AppRoutes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "otp-verification",
    component: OtpVerifyComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "",
    component: FullComponent,
    canActivate: [GuardGuard],
    children: [
      {
        path: "dashboard",
        redirectTo: "/dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "material-distribution",
        loadChildren: () =>
          import("./material-distribution/material-distribution.module").then(
            (m) => m.MaterialDistributionModule
          ),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: "human-resource",
        loadChildren: () =>
          import("./human-resource/human-resource.module").then(
            (m) => m.HumanResourceModule
          ),
      },
      {
        path: "exam",
        loadChildren: () =>
          import("./exam/exam.module").then(
            (m) => m.ExamModule
          ),
      },
      {
        path: "self-care",
        loadChildren: () =>
          import("./self-care/self-care.module").then(
            (m) => m.SelfCareModule
          ),
      },
      {
        path: "admin-service",
        loadChildren: () =>
          import("./admin-service/admin-service.module").then(
            (m) => m.AdminServiceModule
          ),
      },
      {
        path: "system-configuration",
        loadChildren: () =>
          import("./system-configuration/system-configuration.module").then(
            (m) => m.SystemConfigurationModule
          ),
      },
      {
        path: "profile-password-change",
        component: ChangePasswordComponent,
        pathMatch: 'full'
      },
      /*
            {
              path: "profile",
              component: ProfileComponent,
            }, */
      {
        path: "**",
        redirectTo: "/not-found",
      },
      {
        path: "not-found",
        component: NotfoundComponent,
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/not-found",
  },
  {
    path: "not-found",
    component: NotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
