<!-- <mat-card>
  <mat-card-content>
    <mat-card-title>Basic snack-bar</mat-card-title>
    <mat-card-subtitle>matSnackBar is a service for displaying snack-bar notifications. <code class=""><a
          href="https://material.angular.io/components/snack-bar/overview">Official Component</a></code>
    </mat-card-subtitle>
    <mat-form-field>
      <input matInput value="Disco party!" placeholder="Message" #message> </mat-form-field>
    <mat-form-field>
      <input matInput value="Dance" placeholder="Action" #action> </mat-form-field>
    <button mat-raised-button color="warn" (click)="openSnackBar(message.value, action.value)">Show snack-bar</button>
  </mat-card-content>
</mat-card> -->
<div>
  {{data.type}}
  <br />
  <p style="display: flex; align-items: center; margin: 1px 2px;font-size: 15px;">
    <mat-icon style="font-size: 18px;">{{data.icon}}</mat-icon>
    <b><small>{{data.message}}</small></b>
  </p>
</div>