<app-loader *ngIf="loader"></app-loader>

<body class="body-bg">
  <div style="height: 93.6vh; width: 100%">
    <div class="mat-elevation-z8-landing"
      style="margin: 3%; height: 94%; background-color: #f8f8f8;">
      <div fxLayout="row wrap"
        style="position: relative"
        *ngIf="institutionData">
        <!-- Left -->
        <div fxFlex.gt-lg="65"
          fxFlex.gt-md="65"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="small-device hrz-center"
          style="
            height: 80% !important;
            margin-top: 0px !important;
            clear: both;
            background-color: #f8f8f8;
            border-radius: 40px 0px 0px 40px;
          ">
          <div style="padding: 30px 0">
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <img [src]="institutionData.logo"
                alt="Test Image"
                style="height: auto; width: 30px; margin-right: 15px" />
              <h3 style="font-weight: normal">
                {{
                  institutionData.slug.toLowerCase() === "ois"
                    ? "Oxford International School(OIS)"
                    : "Fingerprint Education Management System(FEMS)"
                }}
              </h3>
            </div>
            <img [src]="institutionData.coverImage"
              alt="Cover Image"
              alt="Cover Image"
              style="width: 75%; height: auto" />
          </div>
          <div style="color: gray; text-align: left; padding: 5px"
            class="small-device-text left-side-footer-text">
            <div>
              <span>
                Pay with &nbsp;
                <img [src]="institutionData?.moneybagLogo"
                  alt="fitl"
                  style="width: 40px; height: auto" />
              </span>
              <span>
                Powered By &nbsp;
                <img [src]="institutionData?.poweredByLogo"
                  alt="fitl"
                  style="width: 15px; height: auto" />
              </span>
            </div>
          </div>
        </div>

        <!-- Right  -->
        <div fxFlex.gt-lg="35"
          fxFlex.gt-md="35"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="hrz-center right-side-div mat-line"
          style="background-color: white; margin: 0%; border-radius: 0px 40px 40px 0px;">
          <!-- class="right-big-scren-icon-design" -->
          <!-- ICONS -->
          <div fxLayout="row wrap"
            style="justify-content: center">
            <div *ngFor="let media of institutionData.mediaLinks; let i = index">
              <a [href]="media?.link"
                target="_blank">
                <img [src]="media?.icon"
                  alt="media icon"
                  style="width: 28px" />
              </a>
            </div>
          </div>
          <!-- class="right-big-screen" -->

          <!-- FORM  -->
          <div align="center"
            style="margin-top: 10%;">
            <img [src]="institutionData.logo"
              alt="Test Image"
              class="fitl-logo" />
            <br>
            <br>
            <h5 style="font-weight: bold; margin: 0px;color:black">
              Welcome to
              {{
               institutionData.slug.toLowerCase() === "ois" ? "OIS" : "FEMS"
             }}
            </h5>
            <small>
              OTP 🔒
              <mat-card style="text-align: left !important; padding: 5px !important; margin: 5px !important"
                class="alert-success">
                <span>
                  Verification code send
                  <small *ngIf="
                    verificationInfo.email &&
                    (verificationInfo.verificationType == 1 ||
                      verificationInfo.verificationType == 3)
                  ">
                    Your Email(<b> {{ verificationInfo.email }} </b>)
                    <button type="button"
                      (click)="onResendEmail()"
                      mat-raised-button
                      [disabled]="isLoading"
                      style="width : 5em;">
                      <small>Resend</small>
                    </button>
                  </small>
                  <p *ngIf="
                    verificationInfo.phone &&
                    (verificationInfo.verificationType == 1 ||
                      verificationInfo.verificationType == 2)
                  ">
                    and Phone(<b> {{ verificationInfo.phone }} </b>)
                    <button type="button"
                      mat-raised-button
                      *ngIf="!verificationInfo.isResetSMS"
                      (click)="onResendPhone()"
                      [disabled]="isLoading">
                      Resend
                    </button>
                  </p>
                  Code expired <b>{{ verificationInfo.expired_date }}</b>
                </span>
              </mat-card>
            </small>
            <div style="max-width: 70%; margin-top: 0px !important">
              <form class="example-form"
                [formGroup]="otpData"
                (ngSubmit)="verifyOtp()">
                <mat-form-field>
                  <input formControlName="code"
                    matInput
                    type="number"
                    placeholder="Enter OTP"
                    required
                    minlength="6" />
                  <mat-icon matSuffix>code</mat-icon>
                  <mat-error *ngIf="codeValidation()">{{
                  codeValidation()
                }}</mat-error>

                </mat-form-field>
                <button mat-raised-button
                  color="primary"
                  id="btnSubmit"
                  name="btnSubmit"
                  type="submit"
                  aria-label="Verify Otp"
                  class="did-purple"
                  style="
                  width: 100%;
                  font-weight: bold;
                  margin-top: 20px;
                "
                  [disabled]="isLoading">
                  Verify OTP
                </button>
                <span>
                  <small>
                    <a href="#"
                      style="color: #7367f0 !important"
                      [disabled]="isLoading">
                      < &nbsp;<u>Back To Login</u>
                    </a>
                  </small>
                </span>
              </form>
            </div>
          </div>

          <!-- FOOTER  -->
          <div class="right-side-footer"
            style="margin-top: 5%">
            <small>
              <a [href]="institutionData?.knowladeBasedLink"
                target="_black">
                <u class="right-side-footer-text"> Knowledge Base</u>
              </a>
              <a [href]="institutionData?.videoTutorialLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Video Tutorial</u>
              </a>
              <a [href]="institutionData.faqLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Frequently Asked Question</u>
              </a>
              <a [href]="institutionData.helpDeskLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Helpdesk</u>
              </a>
            </small>
            <br />
            <small>
              Email: {{ institutionData.email }} | Hotline
              {{ institutionData.mobile_no }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>

</body>