import { Router } from "@angular/router";
import { LocalStorageService } from "./../../../services/global.service";
import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { GlobalDataService } from "src/app/services/utils/global-data.service";
import { GlobalService } from "src/app/services/global.service";




@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class AppHeaderComponent implements OnInit {

  fullScreen: boolean = true;
  exitFullScreen: boolean = false;
  public loader: boolean = false;
  public name : any;

  public result : any;
  public userInfo: any;
  constructor (
    private globalData: GlobalDataService,
    private globalD: GlobalService,
    private LocalStorage: LocalStorageService,
    private route: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    // this.refresh();
     this.alldata();

    //  this.name = this.globalData//?.globalData?.user_basic_info?.first_name
    //  console.log('name: ', this.name);

   // this.name=this.userInfo.first_name//"Head of test"//this.globalD.UserInfo()

   this.userInfo=this.globalD.UserInfo()//.getUserInfo?.globalData?.user_basic_info?.first_name;
     console.log('name in Construc: ', this.userInfo);
    // console.log(res.data.user_basic_info)

   }
  elem: any;

  ngOnInit() {
    this.name=this.userInfo.first_name +' ' + this.userInfo.last_name
    console.log('OnInt '+ JSON.stringify( this.userInfo))
    this.elem = document.documentElement;
    this.userInfo = this.globalData.getUserInfo();
    setTimeout(() => {
    }, 5000)
  }
//  ngAfterViewInit() {
// //this.refresh();
// break;

//  }

  alldata(){
    this.result =  this.globalData?.globalData;
  }
  openFullscreen() {
    this.fullScreen = false;
    this.exitFullScreen = true;
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  async closeFullscreen() {
    this.fullScreen = true;
    this.exitFullScreen = false;
    if (await document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  LOGOUT() {
    this.LocalStorage.clearAll();
    this.route.navigate(["/"]);

  }
  refresh(): void {
    window.location.reload();
}
}
