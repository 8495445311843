import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { GlobalService, LocalStorageService } from "../services/global.service";
import { SnackbarService } from "../services/snackbar.service";

@Component({
  selector: "app-otp-verify",
  templateUrl: "./otp-verify.component.html",
  styleUrls: ["./otp-verify.component.css"],
})
export class OtpVerifyComponent implements OnInit {
  institutionData: any;
  verificationInfo: any;
  user_username: any;

  public otpData: any = FormGroup;

  isLoading = false;
  loader: boolean = false

  constructor (
    private LocalStorage: LocalStorageService,
    private route: Router,
    private auth: AuthService,
    private global: GlobalService,
    private snackBar: SnackbarService
  ) {
    this.otpData = new FormGroup({
      user_username: new FormControl(),
      code: new FormControl(),
    });

    this.institutionData = this.LocalStorage.getItem("institutionData") || null;
    this.user_username = this.LocalStorage.getItem("user_username") || null;
    this.otpData.value.user_username = this.user_username;
    this.verificationInfo =
      this.LocalStorage.getItem("verificationData") || null;
  }

  ngOnInit(): void {
    if (!this.institutionData) {
      this.onBackToLogin();
    }

    if (!this.user_username || !this.verificationInfo) {
      this.onBackToLogin();
    }
  }

  async verifyOtp() {
    if ((await this.codeValidation()) !== true) {
      return;
    }

    // assign user_username to otpdata bcz in constructor it is not assgning
    this.otpData.value.user_username = this.user_username;
    this.isLoading = true;
    this.loader = true;

    this.auth
      .forgotPasswordOTPVerify(this.otpData.value)
      .subscribe((res: any) => {
        this.isLoading = false;
        this.loader = false;

        if (res.status && res.otpIsVerified && this.user_username) {
          this.route.navigate(["reset-password"]);
        }
        if (!res.status && res.validation_error) {
          this.snackBar.error(
            res.validation_error.code || "Something went wrong"
          );
          return;
        }

        if (!res.status && res?.validation_error) {
          const error = res?.validation_error;
          this.snackBar.error(error.code);
        }
      });
  }
  onResendEmail() {
    if (this.user_username) {
      this.isLoading = true;
      this.loader = true;
      this.LocalStorage.removeItem("verificationData");
      this.auth
        .emailResend({ user_username: this.user_username })
        .subscribe((res) => {
          this.isLoading = false;
          this.loader = false;
          if (res.isOtpVerification) {
            const data = res?.data;
            this.LocalStorage.setItem("verificationData", data);
            this.verificationInfo = data;
            this.snackBar.success(
              res.message || "Already send OTP please enter the OTP."
            );

            return;
          }
          this.snackBar.success(
            res.message || "Already send OTP please enter the OTP."
          );
          // this.route.navigate(["/"]);
        });
    } else {
      this.onBackToLogin();
    }
  }
  onResendPhone() {
    if (this.user_username) {
      this.isLoading = true;
      this.loader = true;
      this.LocalStorage.removeItem("verificationData");
      this.auth
        .phoneResend({ user_username: this.user_username })
        .subscribe((response) => {
          this.isLoading = false;
          this.loader = false;
          const res = response.data;
          if (res.isOtpVerification) {
            const data = res?.data;
            this.LocalStorage.setItem("verificationData", data);
            this.verificationInfo = data;

            this.snackBar.success(res.message || "OTP sent successfully.");
          }
          if (res.isSMSBLock) {
            this.LocalStorage.removeItem("verificationData");
            this.LocalStorage.removeItem("user_username");

            this.snackBar.error(res.message || "Something went wrong");

            this.route.navigate(["/"]);
          }
        });
    } else {
      this.onBackToLogin();
    }
  }

  codeValidation() {
    if (!this.otpData.value.code) {
      return "OTP Code field is required";
    }
    if (this.otpData.value.code.length < 6) {

      return "OTP Code must be atleast 6 digits";
    }
    return true;
  }
  onBackToLogin() {
    this.LocalStorage.clearAll();
    this.route.navigate(["/"]);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.otpData.controls[controlName].hasError(errorName);
  };

  formValidation() { }
}
