import { Component, Inject } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarModule,
  MatSnackBarConfig,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";

@Component({
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    @Inject(MAT_SNACK_BAR_DATA) public icon: any,
    @Inject(MAT_SNACK_BAR_DATA) public type: any,
    public snackBar: MatSnackBar
  ) {}

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
