import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class EnvService {
  public BASE_URL: string = "";

  constructor() {
    this.BASE_URL = environment.apiUrl;
  }

  public RouteModel: IRouteModel = {
    oisaclRole: {
      read: "oisacl-role/read",
    },
    auth: {
      login: "auth/login",
    },
    menu: {
      allMenu: "read-all-menus/1",
    },
  };
}
interface IRouteModel {
  oisaclRole: {
    read: string;
  };
  auth: {
    login: string;
  };
  menu: {
    allMenu: string;
  };
}
