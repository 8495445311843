<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <mat-toolbar class="topbar telative navColor mat-elevation-z8">
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand"
        routerLink="/dashboard">
        <b>
          <img [src]="institutionData?.favicon"
            alt="homepage"
            class="dark-logo" />
          <div style="display: flex; align-items: center;">
            <img [src]="institutionData?.favicon"
              alt="logo"
              class="light-logo"
              [style]="logoStyle" />
            <small style="line-height: 30px; color: whitesmoke;">{{ institutionData?.slug | uppercase }}</small>
          </div>
        </b>
        <span fxShow="false"
          fxShow.gt-xs>
          <!-- <img src="http://ems.ois.edu.bd/img/ois-avatar.80050842.png" alt="homepage" class="dark-logo">
                 <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
        </span>
      </a>
    </div>
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

    <button mat-icon-button
      (click)="snav.toggle()"
      value="sidebarclosed">
      <mat-icon class="menuToggleIcon">menu</mat-icon>
    </button>
    <span fxFlex></span>
    <!-- ============================================================== -->
    <!-- app header component - style you can find in header.scss / header.component.ts-->
    <!-- ============================================================== -->
    <app-header></app-header>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- sidebar-bg -->
    <mat-sidenav #snav
      id="snav"
      class="dark-sidebar pl-xs mat-elevation-z8 "
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches">
      <app-sidebar></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">
      <div class="page-content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>