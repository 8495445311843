<mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput
    (keyup)="applyFilter($event)"
    placeholder="Ex. Mia"
    #input />
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table
    [dataSource]="dataSource"
    matSort>
    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header>ID</th>
      <td mat-cell
        *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="progress">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header>Progress</th>
      <td mat-cell
        *matCellDef="let row">{{ row.progress }}%</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header>Name</th>
      <td mat-cell
        *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <!-- Fruit Column -->
    <ng-container matColumnDef="fruit">
      <th mat-header-cell
        *matHeaderCellDef
        mat-sort-header>Fruit</th>
      <td mat-cell
        *matCellDef="let row">{{ row.fruit }}</td>
    </ng-container>

    <tr mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row"
      *matNoDataRow>
      <td class="mat-cell"
        colspan="4">
        Data
      </td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[20, 50, 100]"
    aria-label="Select page of users"></mat-paginator>
</div>