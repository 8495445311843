import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from 'src/app/services/utils/global-data.service';

@Component({
  selector: 'app-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.css']
})
export class StickerComponent implements OnInit {
  userInfo:any;

  constructor(private globalData:GlobalDataService) { }

  ngOnInit(): void {
    this.userInfo=this.globalData.getUserInfo();
  }

}
