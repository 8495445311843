import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OldAPIHttpService {
  BASE_URL = environment.oldApiUrl;
  username: string = "ois_admin";
  password: string = "1234";
  digest: any;
  institution_id: number = environment.institutionId;

  constructor(private http: HttpClient) { }

  headers = (url: string) => ({
    headers: {
      Authorization: `Digest username="ois_admin", nonce="164249544830e745090d406e71fef6d01e6f86e1f161e67dd8f138d", uri="${url}", algorithm="MD5", qop=auth, nc=00000001, cnonce="VjKtKRrj", response="9622df2856b89295836eded383eb2183"`,
    },
  });

  get = (path: string): Observable<any> => {
    return this.http
      .get(this.BASE_URL + path, this.headers(path))
      .map((res) => res)
      .catch((err: any) => {
        return Observable.throwError(err);
      });
  };

  post(path: string, formData: any) {
    return this.http.post(
      this.BASE_URL + path,
      { request: { ...formData, ...{ institution_id: this.institution_id } } },
      this.headers(path)
    );
  }

  put = (path: string, formData: any): Observable<any> => {
    return this.http
      .put(this.BASE_URL + path, formData, this.headers(path))
      .map((res) => res)
      .catch((err: any) => Observable.throwError(err));
  };

  delete = (path: string): Observable<any> => {
    return this.http
      .delete(this.BASE_URL + path, this.headers(path))
      .map((res) => res)
      .catch((err: any) => Observable.throwError(err));
  };
}
