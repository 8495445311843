import { HttpV2Service } from './../http-v2.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelfService {

  constructor (
    private httpV2: HttpV2Service
  ) {
  }

  //** search employee */
  searchEmployee(params: any) {

    return this.httpV2.get(`self-care/search-employee/${params.institutionId}/${params.searchObj}`).map(res => res);
  }
  //**  get directory data */
  getDirectoryData = (params: any) => {
    return this.httpV2.get(`self-care/directory-by-id/${params.employeeId}`).map((res: any) => res)
  }

  //** Get Profile data */
  getProfileData = (params: any) => {
    return this.httpV2.get(`self-care/profile-by-id/${params.employeeId}/${params.institutionId}`).map((res: any) => res)

  }

  //** Get payslip data */
  getPaySlipData = (params: any) => {
    return this.httpV2.get(`self-care/payslip-by-id/${params.employeeId}`).map((res: any) => res)
  }

  // ** paySlip view data */
  getPayslipView =(params: any)=>{
    return this.httpV2.get(`self-care/view-payslip/${params.employeeId}/${params.payslipSettingId}/${params.institutionId}`).map((res: any) => res)
  }

  //** Get sms data */
  getSmsData = (params: any) => {
    return this.httpV2.post("self-care/sms", params).map((res: any) => res)
  }

  /**
   * change profile Password
   * though it has been called from auth but the ui functionality is in profile page
   */

  profilePasswordChange = (params: any) => {
    return this.httpV2.post("auth/profile-password-change", params).map((res: any) => res)
  }
}

