import { CdkAccordionModule } from "@angular/cdk/accordion";
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { GuardGuard } from "./auth/guard.guard";
import { CommonMaterialModule } from "./common-material/common-material.module";
import { LoaderModule } from "./common-material/loader/loader.module";
import { ActivityComponent } from "./dashboard/dashboard-components/activity/activity.component";
import { StickerComponent } from "./dashboard/dashboard-components/sticker/sticker.component";
import { DemoMaterialModule } from "./demo-material-module";
import { FemsTableComponent } from "./fems-table/fems-table.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { FullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/full/header/header.component";
import { MenutreeComponent } from "./layouts/full/sidebar/menutree/menutree.component";
import { AppSidebarComponent } from "./layouts/full/sidebar/sidebar.component";
import { LoginComponent } from "./login/login.component";
import { SnackbarComponent } from "./material-component/snackbar/snackbar.component";
import { NotfoundComponent } from "./notfound/notfound.component";
import { OtpVerifyComponent } from "./otp-verify/otp-verify.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { LocalStorageService } from "./services/global.service";
import { GlobalDataService } from "./services/utils/global-data.service";
import { SharedModule } from "./shared/shared.module";
import { SpinnerComponent } from "./shared/spinner.component";


@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    MenutreeComponent,
    LoginComponent,
    // ProfileComponent,
    ActivityComponent,
    StickerComponent,
    NotfoundComponent,
    ForgotPasswordComponent,
    OtpVerifyComponent,
    ResetPasswordComponent,
    SnackbarComponent,
    FemsTableComponent,

    // LoaderComponent
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    // RouterModule.forRoot(AppRoutes),
    AppRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    MatCardModule,
    MatExpansionModule,
    CdkAccordionModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSortModule,
    MatDialogModule,
    CommonMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    GuardGuard,
    GlobalDataService,
    LocalStorageService,
    AppHeaderComponent

  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
