import { CustomLoaderComponent } from './custom-loader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  exports: [CustomLoaderComponent],
  declarations: [
    CustomLoaderComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ]
})
export class CustomLoaderModule { }
