import { Injectable } from "@angular/core";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
// import {  Response, Headers, RequestOptions } from '@angular/http';
import "rxjs/add/operator/toPromise";
import "rxjs/Rx";
import { EnvService } from "./env.service";
import { HttpService } from "./http.service";
import { HttpV2Service } from './http-v2.service';

interface Login {
  user_username: string;
  password: string;
  remember_me: string;
}
interface ForgotPassword {
  user_username: string;
  phone: string;
}
interface ChangePassword {
  user_username: string;
  password: string;
  password_confirmation: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor (
    private envService: EnvService,
    private http: HttpService,
    private httpV2: HttpV2Service
  ) { }

  LOGIN(payload: Login) {
    return this.http
      .post(this.envService.RouteModel.auth.login, payload)
      .map((res) => res);
  }
  tokenBasedLogin(payload: Login) {
    return this.http.post("auth/token-based-login", payload).map((res) => res);
  }
  forgotPassword(payload: ForgotPassword) {
    return this.http.post("auth/forgot-password", payload).map((res) => res);
  }
  forgotPasswordOTPVerify(payload: Login) {
    return this.http.post("auth/otp-verify", payload).map((res) => res);
  }
  emailResend(payload: any) {
    return this.http.post("auth/email-resend-otp", payload).map((res) => res);
  }
  phoneResend(payload: any) {
    return this.http.post("auth/mobile-resend-otp", payload).map((res) => res);
  }
  changePassword(payload: ChangePassword) {
    return this.http
      .post("auth/new-user-password-reset-ng", payload)
      .map((res) => res);
  }

  //* V2 PROCESS FORM NODE
  LoginV2 = (payload: any) => {

    return this.httpV2.post("auth/login", payload).map((res: any) => res);
  }
}
