<app-loader *ngIf="isLoading"></app-loader>

<body class="body-bg"
  *ngIf="institutionData">
  <div style="height: 93.6vh; width: 100%">
    <div class="mat-elevation-z8-landing"
      style="margin: 3%; height: 94%; background-color: #f8f8f8;">
      <div fxLayout="row wrap"
        style="position: relative">
        <div fxFlex.gt-lg="65"
          fxFlex.gt-md="65"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="small-device hrz-center"
          style="
            height: 80% !important;
            margin-top: 0px !important;
            clear: both;
            background-color: #f8f8f8;
            border-radius: 40px 0px 0px 40px;
          ">
          <div style="padding: 30px 0">
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <img [src]="institutionData.logo"
                alt="Test Image"
                style="height: auto; width: 30px; margin-right: 15px" />
              <h3 style="font-weight: normal">
                {{
                  institutionData.slug.toLowerCase() === "ois"
                    ? "Oxford International School(OIS)"
                    : "Fingerprint Education Management System(FEMS)"
                }}
              </h3>
            </div>
            <img [src]="institutionData.coverImage"
              alt="Cover Image"
              alt="Cover Image"
              style="width: 75%; height: auto" />
          </div>
          <div style="color: gray; text-align: left; padding: 5px"
            class="small-device-text left-side-footer-text">
            <div>
              <span>
                Pay with &nbsp;
                <img [src]="institutionData?.moneybagLogo"
                  alt="fitl"
                  style="width: 40px; height: auto" />
              </span>
              <span>
                Powered By &nbsp;
                <img [src]="institutionData?.poweredByLogo"
                  alt="fitl"
                  style="width: 15px; height: auto" />
              </span>
            </div>
          </div>
        </div>
        <div fxFlex.gt-lg="35"
          fxFlex.gt-md="35"
          fxFlex.gt-xs="100"
          fxFlex="100"
          class="hrz-center right-side-div mat-line"
          style="background-color: white; margin: 0%; border-radius: 0px 40px 40px 0px;">
          <div fxLayout="row wrap"
            style="justify-content: center"
            align="center">
            <div *ngFor="let media of institutionData.mediaLinks; let i = index">
              <a [href]="media?.link"
                target="_blank">
                <img [src]="media?.icon"
                  alt="media icon"
                  style="width: 28px" />
              </a>
            </div>
          </div>
          <div align="center"
            style="margin-top: 10%">
            <img [src]="institutionData.logo"
              alt="Test Image"
              class="fitl-logo" />
            <br>
            <br>
            <h5 style="font-weight: bold; margin: 0px; color: black">
              Welcome to
              {{
                institutionData.slug.toLowerCase() === "ois" ? "OIS" : "FEMS"
              }}
            </h5>
            <small> Please sign-in to your account</small>
            <form class="example-form"
              [formGroup]="login"
              (ngSubmit)="LOGIN()"
              style="margin: 0 10%; text-align: left">
              <div class="example-container"
                style="margin-bottom: 10px">
                <mat-form-field hintLabel="Max 10 characters">
                  <mat-label>Enter User ID</mat-label>
                  <input matInput
                    formControlName="user_username"
                    name="user_username"
                    #input
                    maxlength="10"
                    required
                    [disabled]="isLoading"
                    (blur)="userNameBlur()" />
                  <br />
                  <mat-error *ngIf="userNameValidation()">{{
                    userNameValidation()
                  }}</mat-error>

                  <mat-icon matSuffix>perm_identity</mat-icon>
                </mat-form-field>
              </div>
              <div class="example-container"
                style="margin-bottom: 30px">
                <mat-form-field hintLabel="Max 50 characters">
                  <mat-label>Enter Password</mat-label>
                  <input matInput
                    formControlName="password"
                    name="password"
                    [type]="hide ? 'password' : 'text'"
                    required
                    #input
                    maxlength="50"
                    (blur)="passwordBlur()"
                    [disabled]="isLoading"
                    (keypress)="passwordKeyPress($event)" />
                  <mat-error *ngIf="passwordValidation()">{{
                    passwordValidation()
                  }}</mat-error>
                  <button mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                    type="button">
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <small style="display: flex; justify-content: space-between !important">
                <mat-checkbox formControlName="remember_me"
                  name="rememberMe"
                  class="example-margin"
                  style="margin-bottom: 8px">Keep me logged in
                </mat-checkbox>
                <span>
                  <a href="#"
                    style="color: #7367f0 !important"
                    routerLink="/forgot-password"
                    [disabled]="isLoading"><u>Forgot Password</u></a>
                </span>
              </small>
              <button mat-raised-button
                type="submit"
                id="btnSubmit"
                name="btnSubmit"
                aria-label="Sign in"
                style="width: 100%; font-weight: bold"
                class="did-purple"
                [disabled]="isLoading">
                <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
                <mat-icon> verified_user</mat-icon>&nbsp; Login
              </button>
            </form>
          </div>
          <div class="right-side-footer"
            style="margin-top: 5%">
            <small>
              <a [href]="institutionData?.knowladeBasedLink"
                target="_black">
                <u class="right-side-footer-text"> Knowledge Base</u>
              </a>
              <a [href]="institutionData?.videoTutorialLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Video Tutorial</u>
              </a>
              <a [href]="institutionData.faqLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Frequently Asked Question</u>
              </a>
              <a [href]="institutionData.helpDeskLink"
                target="_black">
                <u class="right-side-footer-text">&nbsp;|&nbsp;Helpdesk</u>
              </a>
            </small>
            <br />
            <small>
              Email: {{ institutionData.email }} | Hotline
              {{ institutionData.mobile_no }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>