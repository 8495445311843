import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, ViewChild } from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from "@angular/material/tree";
import { Router } from "@angular/router";
import {
  GlobalService,
  LocalStorageService
} from "src/app/services/global.service";
import { MenuItems } from "./../../../../shared/menu-items/menu-items";
/**
 * Menu data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface MenuNode {
  title: string;
  icon: string;
  children?: MenuNode[];
  route: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  icon: string;
  level: number;
  route: string;
}

/**
 * @title Tree with flat nodes
 */
@Component({
  selector: "app-menutree",
  templateUrl: "./menutree.component.html",
  styleUrls: ["./menutree.component.css"],
})
export class MenutreeComponent {
  private _transformer = (node: MenuNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.title,
      icon: node.icon,
      route: node.route,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource: any = new MatTreeFlatDataSource(
    this.treeControl,
    this.treeFlattener
  );
  menuData: any[] = [];
  searchText: boolean = false
  constructor (
    private router: Router,
    private LocalStorage: LocalStorageService,
    private globalService: GlobalService,
    public menuItems: MenuItems
  ) { }

  @ViewChild("tree") tree: any;
  @ViewChild("description") description: any;

  menuHandle(node: any) { }
  onFocus(): void {
    this.tree.treeControl.expandAll();
  }

  onfocusOut(): void {
    this.tree.treeControl.collapseAll();
  }
  ngOnInit() {
    if (!this.LocalStorage.getItem("menu")) {
      this.globalService.getMenuItems().subscribe((res: any) => {
        if (res.status) {
          this.LocalStorage.setItem("menu", res.data);
          this.dataSource.data = res.data;
          this.menuData = res.data;
        }
      });

      // this.globalService.getMenuItemsV2().subscribe((res: any) => {
      //   if (res.status) {
      //     this.LocalStorage.setItem("menu", res.data);
      //     this.dataSource.data = res.data;
      //     this.menuData = res.data;
      //   }
      // });
    } else {
      this.dataSource.data = this.LocalStorage.getItem("menu");
      this.menuData = this.LocalStorage.getItem("menu") || [];
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnChanges() { }

  result: any[] = [];
  //TODO:: Need to optimization
  treeSearchMenu(data: any, title: string) {
    data?.forEach((item: any) => {
      if (item.children && item.children.length > 0) {
        item?.children?.forEach((child: any) => {
          if (child.children && child.children.length > 0) {
            child?.children?.forEach((subChild: any) => {
              if (subChild.children && subChild.children.length > 0) {
                subChild?.children?.forEach((subChild2: any) => {
                  if (
                    subChild2.title.toLowerCase().includes(title.toLowerCase())
                  ) {
                    this.restructureMenu(item, subChild2);
                  }
                });
              }
              if (subChild.title.toLowerCase().includes(title.toLowerCase())) {
                this.restructureMenu(item, subChild);
              }
            });
          }
          if (child.title.toLowerCase().includes(title.toLowerCase())) {
            this.restructureMenu(item, child);
          }
        });

        // this.menuSearch(item.children, title);
      }
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        this.restructureMenu(item);
      }
    });
  }
  //TODO:: Need to optimization
  restructureMenu(data: any, item?: any) {
    let finalData: any;
    // First layer
    if (!item) {
      finalData = data;
    }
    // Second layer
    if (item?.layer === 2) {
      finalData = {
        ...data,
        ...{
          children: [item],
        },
      };
    }
    // Third layer
    if (item?.layer === 3) {
      let secondLayer = data.children.find((x: any) => x.id === item.parent_id);
      finalData = {
        ...data,
        ...{
          children: [
            {
              ...secondLayer,
              ...{ children: [item] },
            },
          ],
        },
      };
    }

    // Fourth layer
    if (item?.layer === 4) {
      let thirdLayer: any;
      data?.children?.map((second: any) => {
        second?.children?.map((third: any) => {
          if (third.id === item.parent_id) {
            thirdLayer = third;
          }
        });
      });

      let secondLayer = data.children.find(
        (x: any) => x.id === thirdLayer.parent_id
      );
      finalData = {
        ...data,
        ...{
          children: [
            {
              ...secondLayer,
              ...{ children: [{ ...thirdLayer, ...{ children: [item] } }] },
            },
          ],
        },
      };
    }
    this.result.push(finalData);
  }
  //TODO:: Need to optimization
  finalStructure = (data: any) => {
    let finalData: any = [];
    // first layer restructure
    data.forEach((item: any) => {
      const alreadyExistFirstLayer = finalData?.find(
        (x: any) => x.id === item.id
      );
      if (alreadyExistFirstLayer) {
        let child = [...alreadyExistFirstLayer?.children, ...item.children];

        let finalChild: any = [];
        // second layer restructure
        child?.forEach((scF2: any) => {
          const alreadyExistSecondLayer = finalChild?.find(
            (sc2: any) => sc2.id === scF2.id
          );
          if (alreadyExistSecondLayer) {
            let secC = [];
            if (alreadyExistSecondLayer?.children) {
              secC = alreadyExistSecondLayer.children;
            } else {
              secC = [alreadyExistSecondLayer];
            }
            let secFc2 = [];
            if (scF2?.children) {
              secFc2 = scF2.children;
            } else {
              secFc2 = [scF2];
            }
            let child2 = [...secC, ...secFc2];
            let children: any = [];
            // third layer restructure child unique
            child2.forEach((sc2: any) => {
              if (!children.find((ccs: any) => ccs.id === sc2.id)) {
                children.push(sc2);
              }
            });
            let final: any = [];
            finalChild?.forEach((subC: any) => {
              if (subC.id === scF2.id && subC.children) {
                final.push({ ...subC, ...{ children: children } });
              } else {
                final.push(subC);
              }
            });
            finalChild = final;
          } else {
            finalChild.push(scF2);
          }
        });

        finalData.forEach((x: any) => {
          if (x.id === item.id) {
            x.children = finalChild;
          }
        });
      } else {
        finalData.push(item);
      }
    });
    this.result = finalData;
  };

  menuSearch(data: any, title: string) {
    data.map((item: any) => {
      if (item.children && item.children.length > 0) {
        this.menuSearch(item.children, title);
      }
      if (item.title.toLowerCase().includes(title.toLowerCase())) {
        this.result.push(item);
      }
    });
  }

  debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  emptySearch(input: HTMLInputElement) {
    this.searchText = false
    this.dataSource.data = this.menuData;
    input.value = '';
  }
  searchMenu(filterValue: string) {
    this.result = [];
    this.dataSource.data = this.menuData;
    if (!filterValue) {
      this.searchText = false
      return
    };
    this.searchText = true
    // this.menuSearch(this.dataSource.data, filterValue);
    this.treeSearchMenu(this.dataSource.data, filterValue);
    this.finalStructure(this.result);
    this.dataSource.data = this.result;
    if (this.result.length > 0) {
      this.onfocusOut();
      setTimeout(() => {
        this.onFocus();
      }, 100);
    }
  }

  // onSearch(filterValue: string) {
  //   this.debounce(this.searchMenu(filterValue));
  // }

  onGotoRoute = (route: any) => {
    if (!route) {
      this.router.navigate(["not-found"]);
      return;
    }
    this.router.navigate([route]);
  };
}
