import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SelfService } from '../../../services/self-service/self-service.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/global.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  nameParent: string = "Self-Care/ Profile/ Change-Password"

  public hide1: boolean = true;
  public hide2: boolean = true;
  public hide3: boolean = true;

  public loader: boolean = false

  PasswordChangeForm: any = FormGroup

  constructor (
    private SelfService: SelfService,
    private router: Router,
    private localStorage: LocalStorageService,
    private snackBar: SnackbarService

  ) { }

  ngOnInit(): void {
    this.PasswordChangeForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    });
  }


  changePassword() {
    if (this.PasswordChangeForm.valid) {
      this.loader = true
      let params = {
        old: this.PasswordChangeForm.get('oldPassword').value,
        new: this.PasswordChangeForm.get('newPassword').value,
        confirm: this.PasswordChangeForm.get('confirmPassword').value
      }

      this.SelfService.profilePasswordChange(params).subscribe(res => {
        if (res?.status) {
          this.loader = false
          this.snackBar.success(res?.message)
          window.location.reload();
          // setTimeout(() => {
          //   this.localStorage.clearAll();
          //   this.router.navigate(['/']);
          // }, 1000);
          return
        }
        this.loader = false
        this.snackBar.error(res?.message)
      },
        err => {
          this.loader = false
        })
    }
  }

  passwordLostFocus(event: any) {
    if (event.target.value.length > 0) {
      if (!this.hasNumber(event.target.value) || !this.hasSpecialChar(event.target.value) || !this.isUpperCase(event.target.value) || !this.isLowerCase(event.target.value)) {
        this.snackBar.error("Password must contain- 1 Uppercase, 1 Lowercase, 1 numeric, 1 symbol")
        return
      }
    }
    if (event.target.value.length < 8) {
      this.snackBar.error("Password must be at least 8 characters")
    }
  }

  confirmPasswordLostFocus(event: any) {
    if (event.target.value !== this.PasswordChangeForm.get('newPassword').value) {
      this.snackBar.error("Password and confirm password does not match")
    }
  }
  hasNumber = (str: string) => {
    return /\d/.test(str);
  }
  hasSpecialChar = (str: string) => {
    return /[@$!%*#?&]+/.test(str)
  }

  isUpperCase = (str: string) => {
    return /[A-Z]/.test(str)
  }
  isLowerCase = (str: string) => {
    return /[a-z]/.test(str)
  }


  resetFields() {
    this.PasswordChangeForm.reset();
    // this.PasswordChangeForm.get('oldPassword').setValue('');
    // this.PasswordChangeForm.get('newPassword').setValue('');
    // this.PasswordChangeForm.get('confirmPassword').setValue('');
    // this.PasswordChangeForm.markAllAsTouched();
    // this.PasswordChangeForm.markAsPristine();
  }
}
