import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { SnackbarService } from "../services/snackbar.service";
import { GuardGuard } from "./../auth/guard.guard";
import { AuthService } from "./../services/auth.service";
import {
  GlobalService,
  LocalStorageService
} from "./../services/global.service";
import { oisMediaLink, pageData } from "./../shared/institution-data";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  favicon: any = document.getElementById("favicon");
  title: any = document.getElementById("title");
  accessToken: any;
  hide = true;
  preloader: boolean = true;
  isLoading: boolean = false;
  /* public login: any = {
    user_username: "2002170001",
    password: "",
    remember_me: false,
  }; */

  public loader: boolean = false;
  public login: any = FormGroup;

  institutionData: any;
  errorMsg: any = {
    user_username: "",
    password: "",
  };
  // isNew: boolean = false;

  constructor (
    private _auth: AuthService,
    private globalService: GlobalService,
    private router: Router,
    private LocalStorage: LocalStorageService,
    private myGuard: GuardGuard,
    private snackbar: SnackbarService
  ) {
    this.accessToken = this.LocalStorage.getItem("access_token");
    // this.isNew = this.LocalStorage.getItem("isNew") || false;
    if (this.LocalStorage.getItem("user") && this.accessToken) {
      this.router.navigate(["/dashboard"]);
    } else {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit(): void {
    this.login = new FormGroup({
      user_username: new FormControl(),
      password: new FormControl(),
      remember_me: new FormControl(),
    });

    if (this.LocalStorage.getItem("institutionData")) {
      this.institutionData = this.LocalStorage.getItem("institutionData");
    } else {
      this.getInstitutionData();
      // this.getInstitutionDataV2();
    }
  }

  passwordKeyPress(event: any) {
    if (event.key == 'Enter') {
      this.LOGIN()
    }
  }

  async LOGIN() {

    if (!this.login?.value?.remember_me) {
      this.login.value.remember_me = false;
    }

    if (
      (await this.userNameValidation()) !== true &&
      (await this.passwordValidation()) !== true
    )
      return;

    this.isLoading = true;
    this.loader = true;

    if (
      this.accessToken &&
      this.LocalStorage.getItem("oldUser") === this.login.value.user_username
    ) {
      this._auth.tokenBasedLogin(this.login.value).subscribe(
        async (res: any) => {
          this.isLoading = false;

          if (res.status && res.isLogin) {
            await this.LocalStorage.setItem("user", this.login.value.user_username);
            this.myGuard.loginFlag = true;
            await this.getAllDataFromNewApi();
            // await this.getAllDataFromNewApiV2();
            await this.router.navigate(["/dashboard"]);
            return;
          }
        },
        (err) => {

          this.isLoading = false;
          // console.log(err);
        }
      );
    }

    //TODO:: NEW LOGIN API WILL BE CALLED
    // this._auth.LoginV2(this.login.value).subscribe(async (res: any) => {
    //
    //
    //   if (!res.status && res?.validation_error) {
    //     const validation = res.validation_error;
    //
    //     if (validation.user_username) {
    //       this.snackbar.error(validation.user_username);
    //     }
    //
    //     if (validation.password) {
    //       this.snackbar.error(validation.password);
    //     }
    //     this.isLoading = false;
    //
    //     return;
    //   }
    //   if (res.status && res.isLogin) {
    //     this.LocalStorage.setItem("access_token", res.access_token);
    //     this.LocalStorage.setItem("user", this.login.value.user_username);
    //     this.LocalStorage.setItem("oldUser", this.login.value.user_username);
    //     this.myGuard.who = this.login.value.user_username;
    //     this.myGuard.loginFlag = true;
    //
    //     await this.getAllDataFromNewApiV2()
    //     this.LocalStorage.setItem("isRemember", this.login.value.remember_me);
    //
    //     if (!this.LocalStorage.getItem("institutionData"))
    //       await this.getInstitutionDataV2();
    //
    //     await this.getInstitutionAuthDataV2();
    //
    //     // if not get local storage data
    //     if (!this.LocalStorage.getItem("institutionData")) {
    //       setTimeout(() => {
    //         location.reload();
    //       }, 1000);
    //       return;
    //     }
    //
    //     await this.getUserInstitutionData()
    //     setTimeout(() => {
    //       this.isLoading = false;
    //       this.router.navigate(["/dashboard"]);
    //     }, 1200);
    //
    //     return;
    //   }
    //   //For password reset
    //
    //   if (res.isPasswordReset) {
    //     const data = res?.data;
    //
    //     this.snackbar.info(res.message);
    //     this.isLoading = false;
    //
    //     this.LocalStorage.setItem("user_username", data.user_username);
    //     this.LocalStorage.setItem("verificationData", data);
    //     this.router.navigate(["/reset-password"]);
    //     return;
    //   }
    //
    //   //For new user opt verification
    //   if (res.isOtpVerification) {
    //     const data = res?.data;
    //     this.LocalStorage.setItem("user_username", data.user_username);
    //     this.LocalStorage.setItem("verificationData", data);
    //
    //     this.snackbar.success(res.message);
    //     this.isLoading = false;
    //
    //     this.router.navigate(["/otp-verification"]);
    //     return;
    //   }
    //   this.isLoading = false;
    //   this.snackbar.error(res.message || "Credential not match");
    //
    //   this.router.navigate(["/"]);
    //   this.LocalStorage.clearAll();
    // });

    // Normal Login
    this._auth.LOGIN(this.login.value).subscribe(async (res: any) => {
      //For Login
      if (!res.status && res?.validation_error) {
        const validation = res.validation_error;

        if (validation.user_username) {
          this.snackbar.error(validation.user_username);
        }

        if (validation.password) {
          this.snackbar.error(validation.password);
        }
        this.isLoading = false;

        return;
      }
      if (res.status && res.isLogin) {
        this.LocalStorage.setItem("access_token", res.access_token);
        this.LocalStorage.setItem("user", this.login.value.user_username);
        this.LocalStorage.setItem("oldUser", this.login.value.user_username);
        this.myGuard.who = this.login.value.user_username;
        this.myGuard.loginFlag = true;

        await this.getAllDataFromNewApi()

        this.LocalStorage.setItem("isRemember", this.login.value.remember_me);

        if (!this.LocalStorage.getItem("institutionData"))
          await this.getInstitutionData();

        await this.getInstitutionAuthData();

        // if not get local storage data
        if (!this.LocalStorage.getItem("institutionData")) {
          setTimeout(() => {
            location.reload();
          }, 1000);
          return;
        }

        setTimeout(() => {
          this.isLoading = false;
          this.router.navigate(["/dashboard"]);
        }, 1200);

        return;
      }
      //For password reset

      if (res.isPasswordReset) {
        const data = res?.data;

        this.snackbar.info(res.message);
        this.isLoading = false;

        this.LocalStorage.setItem("user_username", data.user_username);
        this.LocalStorage.setItem("verificationData", data);
        this.router.navigate(["/reset-password"]);
        return;
      }

      //For new user opt verification
      if (res.isOtpVerification) {
        const data = res?.data;
        this.LocalStorage.setItem("user_username", data.user_username);
        this.LocalStorage.setItem("verificationData", data);

        this.snackbar.success(res.message);
        this.isLoading = false;

        this.router.navigate(["/otp-verification"]);
        return;
      }
      this.isLoading = false;
      this.snackbar.error(res.message || "Credential not match");

      this.router.navigate(["/"]);
      this.LocalStorage.clearAll();
    });
  }
  // before login get institution data V2
  getInstitutionDataV2() {

    let preloaderText: any = document.getElementById("preloader-text");

    this.globalService.getInstitutionV2().subscribe((res: any) => {
      if (!res.status) {
        this.favicon.href = "";
        this.title.innerHTML = "Something went wrong!";
        preloaderText.innerHTML =
          "Something went wrong!, please contact with respective dept";
        this.preloader = true;
        return;
      }

      const resData = res.data;
      if (resData.slug.toLowerCase() === "ois") {
        this.institutionData = resData;
        this.institutionData.name = resData.name;
        this.institutionData.logo = pageData.oisLogo;
        this.institutionData.slug = "ois";
        this.institutionData.welcomeMsg = "OIS";
        this.institutionData.poweredByLogo = pageData.oisfavIcon;
        this.institutionData.moneybagLogo = pageData.moneybagLogo;
        this.institutionData.coverImage = pageData.oisCoverImage;
        this.institutionData.mediaLinks = oisMediaLink;
        this.institutionData.knowladeBasedLink =
          "https://oxfordintl.freshdesk.com/support/home";
        this.institutionData.helpDeskLink =
          "https://oxfordintl.freshdesk.com/support/home";
        this.institutionData.faqLink =
          "https://oxfordintl.freshdesk.com/support/solutions/folders/43000017580";
        this.institutionData.videoTutorialLink =
          "https://youtube.com/c/OxfordInternationalSchoolDhaka";
        this.institutionData.favicon = pageData.oisfavIcon;
        this.favicon.href = pageData.oisfavIcon;
      } else {
        this.institutionData = resData || pageData;
        this.institutionData.welcomeMsg = "FEMS";
        this.institutionData.logo = pageData.femsLogo;
        this.institutionData.poweredByLogo = pageData.femsLogo;
        this.institutionData.moneybagLogo = pageData.moneybagLogo;
        this.institutionData.coverImage = pageData.femsCoverImage;
        this.institutionData.mediaLinks = pageData.mediaLinks;
        this.institutionData.favicon = pageData.femsLogo;
        this.favicon.href = pageData.femsLogo;
      }
      this.LocalStorage.setItem("institutionData", this.institutionData);
      // this.institutionData = res.data;
    });
  }
  // after login get institution auth data
  getInstitutionAuthDataV2() {
    this.globalService.getInstitutionAuthV2().subscribe(async (res: any) => {
      const resData = res.data;
      if (resData.slug.toLowerCase() === "ois") return;
      this.institutionData = this.institutionData;
      this.institutionData.favicon =
        Number(resData.id) === 2 ? pageData.apbnpscFavIcon : pageData.femsLogo;

      this.favicon.href =
        Number(resData.id) === 2 ? pageData.apbnpscFavIcon : pageData.femsLogo;
      this.title.innerHTML = resData.name;
      this.institutionData.welcomeMsg = resData.name;
      this.institutionData.slug = resData.slug;
      await this.LocalStorage.setItem("institutionData", this.institutionData);
      return;
    });
  }

  // before login get institution data
  getInstitutionData() {
    let preloaderText: any = document.getElementById("preloader-text");

    this.globalService.getInstitution().subscribe((res: any) => {
      if (!res.status) {
        this.favicon.href = "";
        this.title.innerHTML = "Something went wrong!";
        preloaderText.innerHTML =
          "Something went wrong!, please contact with respective dept";
        this.preloader = true;
        return;
      }

      const resData = res.data;
      if (resData.slug.toLowerCase() === "ois") {
        this.institutionData = resData;
        this.institutionData.name = resData.name;
        this.institutionData.logo = pageData.oisLogo;
        this.institutionData.slug = "ois";
        this.institutionData.welcomeMsg = "OIS";
        this.institutionData.poweredByLogo = pageData.oisfavIcon;
        this.institutionData.moneybagLogo = pageData.moneybagLogo;
        this.institutionData.coverImage = pageData.oisCoverImage;
        this.institutionData.mediaLinks = oisMediaLink;
        this.institutionData.knowladeBasedLink =
          "https://oxfordintl.freshdesk.com/support/home";
        this.institutionData.helpDeskLink =
          "https://oxfordintl.freshdesk.com/support/home";
        this.institutionData.faqLink =
          "https://oxfordintl.freshdesk.com/support/solutions/folders/43000017580";
        this.institutionData.videoTutorialLink =
          "https://youtube.com/c/OxfordInternationalSchoolDhaka";
        this.institutionData.favicon = pageData.oisfavIcon;
        this.favicon.href = pageData.oisfavIcon;
      } else {
        this.institutionData = resData || pageData;
        this.institutionData.welcomeMsg = "FEMS";
        this.institutionData.logo = pageData.femsLogo;
        this.institutionData.poweredByLogo = pageData.femsLogo;
        this.institutionData.moneybagLogo = pageData.moneybagLogo;
        this.institutionData.coverImage = pageData.femsCoverImage;
        this.institutionData.mediaLinks = pageData.mediaLinks;
        this.institutionData.favicon = pageData.femsLogo;
        this.favicon.href = pageData.femsLogo;
      }
      this.LocalStorage.setItem("institutionData", this.institutionData);
      // this.institutionData = res.data;
    });
  }
  // after login get institution auth data
  getInstitutionAuthData() {
    this.globalService.getInstitutionAuth().subscribe(async (res: any) => {
      const resData = res.data;
      if (resData.slug.toLowerCase() === "ois") return;
      this.institutionData = this.institutionData;
      this.institutionData.favicon =
        Number(resData.id) === 2 ? pageData.apbnpscFavIcon : pageData.femsLogo;

      this.favicon.href =
        Number(resData.id) === 2 ? pageData.apbnpscFavIcon : pageData.femsLogo;
      this.title.innerHTML = resData.name;
      this.institutionData.welcomeMsg = resData.name;
      this.institutionData.slug = resData.slug;
      await this.LocalStorage.setItem("institutionData", this.institutionData);
      return;
    });
  }

  // get all data from new api
  getAllDataFromNewApi = () => {
    this.globalService.getAllDataFormNewApi().subscribe((res: any) => {
      this.LocalStorage.setItem("allData", res.data);
    });
  }

  // get all data from new api
  getAllDataFromNewApiV2 = () => {
    this.globalService.getAllDataFormNewApiV2({
      userId: +this.login.value.user_username,
      sessionId: 33
    })
      .subscribe((res: any) => {
        this.LocalStorage.setItem("allData", res.data);
      });
  }
  // user name validation
  userNameValidation(msg?: string) {
    if (!this.login.value.user_username) {
      return "User Id field is required";
    }
    return true;
  }
  // password validation
  passwordValidation() {
    if (!this.login.value.password) {
      return "Password field is required";
    }
    return true;
  }


  getUserInstitutionData() {
    this.globalService.getUserInstitutionData()
      .subscribe((res: any) => {
        this.LocalStorage.setItem("userData", res.data);
      });
  }

  passwordBlur() {

  }
  userNameBlur() {

  }
}
