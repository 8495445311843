<div>
  <mat-card style="box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 1px 3px 1px rgb(60 64 67 / 15%) !important; border-radius: 70px;
      height: 49px;width: 250px;">
    <div style="margin: 0px 15px 0px 15px;">
      <mat-form-field appearance="legacy">
        <mat-label>Search...</mat-label>
        <input matInput
          #searchInput
          (keyup)="searchMenu($event.target.value)"
          style="margin-bottom: 5px !important;">
        <mat-icon matSuffix
          style="color: #283593;"
          *ngIf="!searchText">search</mat-icon>
        <mat-icon matSuffix
          *ngIf="searchText"
          (click)="emptySearch(searchInput)"
          style="color: red;cursor: pointer">close</mat-icon>
      </mat-form-field>
    </div>
  </mat-card>
</div>
<mat-nav-list appAccordion>
  <mat-list-item appAccordionLink
    *ngFor="let menuitem of menuItems.getMenuitem()"
    routerLinkActive="selected"
    group="{{menuitem.state}}">
    <a class=""
      appAccordionToggle
      [routerLink]="['/', menuitem.state]"
      *ngIf="menuitem.type === 'link'">
      <mat-icon style="font-size: 31px!important; margin-top: -5px;">{{ menuitem.icon }}</mat-icon>
      <span style="font-size: 20px;">{{ menuitem.name }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}"
        *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
    </a>
  </mat-list-item>
</mat-nav-list>
<mat-nav-list appAccordion>
  <mat-tree [dataSource]="dataSource"
    [treeControl]="treeControl"
    #tree
    class="">
    <!-- sidebar-bg -->

    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node"
      matTreeNodePadding
      routerLinkActive="selected">
      <!-- use a disabled button to provide padding for tree leaf -->
      <mat-list-item appAccordionLink
        routerLinkActive="selected"
        group="{{ node.route }}">
        <a class="menu-item-hover"
          [routerLink]=""
          appAccordionToggle
          (click)="onGotoRoute(node.route)">
          <mat-icon style="font-size: 1.45rem !important">{{ node.icon || "account_balance" }}</mat-icon>
          <span>{{ node.name }}</span>
          <span fxFlex></span>
        </a>
      </mat-list-item>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding>
      <mat-list-item appAccordionLink
        routerLinkActive="selected"
        group="{{ node.route }}"
        class="mat-list-item-hover">
        <a class="menu-item-hover"
          appAccordionToggle
          [ngClass]="treeControl.isExpanded(node) ? 'active-menu' : ''">
          <mat-icon style="font-size: 1.45rem !important;">{{ node.icon }}</mat-icon>
          <span [attr.aria-label]="'Toggle ' + node.name"
            matTreeNodeToggle>{{ node.name }} </span>
          <span fxFlex
            [attr.aria-label]="'Toggle ' + node.name"
            matTreeNodeToggle></span>
          <button mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror"
              style="margin-top: 0px !important; font-size: 25px;">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
        </a>
      </mat-list-item>
      <!-- <mat-icon style="font-size: 15px; margin-top: 10px" NgIf="node.icon">{{
      node.icon
    }}</mat-icon>
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
      {{ node.name }}
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </button> -->
    </mat-tree-node>
  </mat-tree>
</mat-nav-list>