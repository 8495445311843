import { Injectable } from "@angular/core";
import { LocalStorageService } from "../global.service";

@Injectable({
  providedIn: "root",
})
export class GlobalDataService {
  globalData: any;
  institutionData: any;
  constructor (private localStore: LocalStorageService) {
    this.globalData = this.localStore.getItem("allData");
    this.institutionData = this.localStore.getItem("institutionData");
  }

  // Blood group list
  getBloodGroup = () => {
    if (this.globalData && this.globalData.bloodGroup) {
      return this.globalData.bloodGroup;
    }
    return [];
  };

  // blood group name
  getBloodGroupName = (id: number): string => {
    if (this.globalData && this.globalData.bloodGroup) {
      return this.globalData.bloodGroup.find((x: any) => x.id === id).name;
    }
    return "";
  };

  // Branch or Campus list
  getBranchOption = () => {
    if (this.globalData && this.globalData.branch_info) {
      return this.globalData.branch_info;
    }
    return [];
  };

  // Branch name
  getBranchName = (id: number): string => {
    if (this.globalData && this.globalData.branch_info) {
      return this.globalData.branch_info.find((x: any) => x.branch_id === id)
        .name;
    }
    return "";
  };
  // Department list
  getDepartmentList = () => {
    if (this.globalData && this.globalData.department_info) {
      return this.globalData.department_info;
    }
    return [];
  };
  // Department name
  getDepartmentName = (id: number): string => {
    if (this.globalData && this.globalData.department_info) {
      return this.globalData.department_info.find(
        (x: any) => x.department_id === id
      ).text;
    }
    return "";
  };
  // Designation list
  getDesignationList = () => {
    if (this.globalData && this.globalData.designation_info) {
      return this.globalData.designation_info;
    }
    return [];
  };
  // Designation name
  getDesignationName = (id: number): string => {
    if (this.globalData && this.globalData.designation_info) {
      return this.globalData.designation_info.find((x: any) => x.value === id)
        .text;
    }
    return "";
  };

  // get country list
  getCountryList = () => {
    if (this.globalData && this.globalData.country_info) {
      return this.globalData.country_info;
    }
    return [];
  };
  // country name
  getCountryName = (id: number): string => {
    if (this.globalData && this.globalData.country_info) {
      return this.globalData.country_info.find((x: any) => x.value === id).text;
    }
    return "";
  };

  // division list
  getDivisionList = () => {
    if (this.globalData && this.globalData.division_info) {
      return this.globalData.division_info;
    }
    return [];
  };
  // division name
  getDivisionName = (id: number): string => {
    if (this.globalData && this.globalData.division_info) {
      return this.globalData.division_info.find((x: any) => x.value === id)
        .text;
    }
    return "";
  };

  // District list
  getDistrictList = () => {
    if (this.globalData && this.globalData.district_info) {
      return this.globalData.district_info;
    }
    return [];
  };

  // District name
  getDistrictName = (id: number): string => {
    if (this.globalData && this.globalData.district_info) {
      return this.globalData.district_info.find((x: any) => x.value === id)
        .text;
    }
    return "";
  };

  // Thana List
  getThanaList = () => {
    if (this.globalData && this.globalData.thana_info) {
      return this.globalData.thana_info;
    }
    return [];
  };
  // Thana name
  getThanaName = (id: number): string => {
    if (this.globalData && this.globalData.thana_info) {
      return this.globalData.thana_info.find((x: any) => x.value === id).text;
    }
    return "";
  };

  // get religion list
  getReligionList = () => {
    if (this.globalData && this.globalData.religion) {
      return this.globalData.religion;
    }
    return [];
  };
  // get religion name
  getReligionName = (id: number): string => {
    if (this.globalData && this.globalData.religion) {
      return this.globalData.religion.find((x: any) => x.value == id).text;
    }
    return "";
  };
  // get marital status list
  getMaritalStatusList = () => {
    if (this.globalData && this.globalData.marital_status) {
      return this.globalData.marital_status;
    }
    return [];
  };

  // get marital status name
  getMaritalStatusName = (id: number): string => {
    if (this.globalData && this.globalData.marital_status) {
      return this.globalData.marital_status.find((x: any) => x.value == id)
        .text;
    }
    return "";
  };
  //get nationalities list
  getNationalityList = () => {
    return [
      { id: 0, name: "Bangladeshi", checked: true },
      { id: 1, name: "NRB", checked: false },
      { id: 2, name: "Foreign National", checked: false },
    ];
  };

  // get nationality
  getNationality = (value: any) => {
    if (parseInt(value) === 0) {
      return "Bangladeshi";
    } else if (parseInt(value) === 1) {
      return "NRB";
    } else if (parseInt(value) === 2) {
      return "Foreign";
    }
  };

  // get employee type
  getEmployeeType = (value: any) => {
    if (this.globalData && this.globalData.employment_type) {
      return this.globalData.employment_type;
    }
    return [];
  };

  // get employee type name
  getEmployeeTypeName = (type: any) => {
    if (this.globalData && this.globalData.employment_type) {
      return this.globalData.employment_type.find((x: any) => x.value == type)
        .text;
    }
    return "";
  };

  // get salary mode
  getSalaryMode = () => {
    if (this.globalData && this.globalData.salary_mode) {
      return this.globalData.salary_mode;
    }
    return [];
  };
  // get salary mode name
  getSalaryModeName = (mode: any) => {
    if (this.globalData && this.globalData.salary_mode) {
      return this.globalData.salary_mode.find((x: any) => x.value == mode).text;
    }
    return "";
  };

  // resignation type list
  getResignationTypeList = () => {
    if (this.globalData && this.globalData.resignation_type) {
      return this.globalData.resignation_type;
    }
    return [];
  };
  // get resignation type name
  getResignationTypeName = (type: any) => {
    if (this.globalData && this.globalData.resignation_type) {
      return this.globalData.resignation_type.find((x: any) => x.value == type)
        .text;
    }
    return "";
  };
  // get logged in use id
  getLoggedUser = () => {
    return this.localStore.getItem("user");
  }
  //get al session list
  getAllSession = () => {
    if (this.globalData && this.globalData.session_info) {
      return this.globalData.session_info;
    }
    return [];
  }

  //get user info
  getUserInfo =  () => {
    if (this.globalData && this.globalData.user_basic_info) {
      return this.globalData.user_basic_info;
    }
    return [];
  }

  // get institution info
  getInstitutionInfo = () => {
    if (this.institutionData) {
      return this.institutionData;
    }
    return []
  }

  // get class list
  getClassList = () => {
    if (this.globalData && this.globalData.class_info) {
      return this.globalData.class_info;
    }
    return [];
  }
}
