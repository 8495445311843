<div fxLayout="row wrap"
  class="mat-elevation-z8"
  style="justify-content: center; padding: 30px; margin-top: 10%;background-color: white;">
  <div fxFlex.gt-lg="30"
    fxFlex.gt-md="30"
    fxFlex.gt-xs="100"
    fxFlex="100"
    style="text-align: center">
    <div style="margin-top: 30%;">
      <h1>404</h1>
      <h3 class="m-b-0"
        color="primary">Page not found</h3>
      <button mat-raised-button
        color="primary"
        routerLink="/"
        style="margin-top: 10px">
        Go to Home
      </button>
    </div>
  </div>
  <div fxFlex.gt-lg="40"
    fxFlex.gt-md="40"
    fxFlex.gt-xs="100"
    fxFlex="100">
    <img mat-card-image
      src="assets/images/not-found.gif"
      alt="Image"
      style="height: 300px !important; object-fit: fill" />
  </div>
</div>