/* eslint-disable global-require */
export const pageData = {
  oisLogo: "assets/images/logo/ois-logo.png",
  oisfavIcon: "assets/images/logo/ois-avatar.png",
  apbnLogo: "assets/images/logo/apbn-logo.png",
  femsLogo: "assets/images/logo/fems-icon.png",
  apbnpscFavIcon: "assets/images/logo/apbnnpsc.png",
  femsCoverImage: "assets/images/logo/cover/FEMS.svg",
  oisCoverImage: "assets/images/logo/cover/OIS.svg",
  preloaderIcon: "assets/images/logo/preloader.png",
  moneybagLogo: "assets/images/logo/moneybag-logo.png",
  poweredByLogo: "assets/images/logo/fems-icon.png",
  mediaLinks: [
    {
      id: "1",
      name: "facebook",
      link: "https://www.facebook.com/fingerprintems/",
      icon: "assets/images/logo/facebook.svg",
    },
    {
      id: "2",
      name: "youtube",
      link: "https://www.youtube.com/channel/UCtGtDoTqgiYsH-qQCXdtqoA",
      icon: "assets/images/logo/youtube.svg",
    },
    {
      id: "3",
      name: "linkedin",
      link: "https://www.linkedin.com/company/fitl/",
      icon: "assets/images/logo/linkedin.svg",
    },
  ],
};
export const oisMediaLink = [
  {
    id: "1",
    name: "facebook",
    link: "https://www.facebook.com/oisbd1987",
    icon: "assets/images/logo/facebook.svg",
  },
  {
    id: "2",
    name: "youtube",
    link: "https://youtube.com/c/OxfordInternationalSchoolDhaka",
    icon: "assets/images/logo/youtube.svg",
  },
  {
    id: "3",
    name: "linkedin",
    link: "https://www.linkedin.com/company/oxford-international-school",
    icon: "assets/images/logo/linkedin.svg",
  },
];

export const getLogo = (institutionId: number) =>
  `http://core.fems.education/content/institution_logo/${institutionId}_50_50.png`;
